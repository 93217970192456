// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-getting-started-tsx": () => import("./../../../src/pages/getting_started.tsx" /* webpackChunkName: "component---src-pages-getting-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-judges-tsx": () => import("./../../../src/pages/judges.tsx" /* webpackChunkName: "component---src-pages-judges-tsx" */),
  "component---src-pages-prizes-tsx": () => import("./../../../src/pages/prizes.tsx" /* webpackChunkName: "component---src-pages-prizes-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-pages-submission-guidelines-tsx": () => import("./../../../src/pages/submission_guidelines.tsx" /* webpackChunkName: "component---src-pages-submission-guidelines-tsx" */),
  "component---src-pages-ux-events-tsx": () => import("./../../../src/pages/ux_events.tsx" /* webpackChunkName: "component---src-pages-ux-events-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */)
}

